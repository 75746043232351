// class AuthService {
//     _resetAuthData() {
//         // reset userData in store
//         // $store.commit("UNSET_USER", {});
//         localStorage.setItem("accessToken", "");
//         localStorage.setItem("user", "");
//     }

//     export function makeLogout() {
//         return new Promise((resolve, reject) => {
//           new Http()
//             .post(api.auth.logout)
//             .then((response) => {
//               _resetAuthData();
//               $router.push({ name: "login" }).catch(() => {});
//               return resolve(new ResponseWrapper(response, response.data));
//             })
//             .catch((error) => reject(new ErrorWrapper(error)));
//         });
//       }

//       export function deleteAuthData() {
//         _resetAuthData();
//       }
// }

const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class AuthService {
    static login(parameters) {
        // const params = {
        //   auth: false,
        //   ...parameters,
        // };

        return new Promise((resolve, reject) => {
            return httpClient
                .post(`${baseApiUrl}/authenticate`, { auth: false, ...parameters })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    static impersonate(admin, username, password) {
        return new Promise((resolve, reject) => {
            return httpClient
                .get(`${baseApiUrl}/impersonate/${admin}/${username}/${password}`, { auth: false })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    static impersonateToken(token, username) {
        return new Promise((resolve, reject) => {
            return httpClient
                .get(`${baseApiUrl}/impersonate-v2/${token}/${username}`, { auth: false })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    static recoverPassword(username) {
        return httpClient.post(`${baseApiUrl}/users/recover-password`, { auth: false, username });
    }

    static redefinePassword(password, confirmPassword, token) {
        return httpClient.post(`${baseApiUrl}/users/reset-password`, { auth: false, password, confirmPassword, token });
    }

    static changePassword(password, confirmPassword, token) {
        return httpClient.post(`${baseApiUrl}/users/change-password`, { auth: false, password, confirmPassword, token });
    }

    static checkAuthenticationToken(user_id) {
        return httpClient.post(`${baseApiUrl}/users/token/check`, { user_id });
    }
}

// login

// logout

// request password rete

// redefine password

// check if token is valid

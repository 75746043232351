<template>
  <div class="pwa-notification">
    <Notification>
      <template v-slot:lefticon>
        <img src="../assets/images/Notifications/notification-lady.svg" alt="notification lady" />
        <!-- <img src="../assets/images/Notifications/xmas_eva.svg" alt="notification lady" /> -->
      </template>
      <div class="pwa-notification__content">
        <p>Sabias que podes instalar o myArena no teu telemóvel?</p>
        <button class="main-btn pwa-notification__content__button" @click="installPwa">Instalar</button>
      </div>
    </Notification>
  </div>
</template>

<script>
import Notification from "./Dashboard/Notification/Notification.vue";
export default {
  components: { Notification },
  props: {
    deferredPrompt: { type: Object },
  },
  methods: {
    async installPwa() {
      this.deferredPrompt.prompt();
      this.deferredPrompt = null;
      this.showInstallNotification = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import '../assets/scss/app.scss';
@import '../assets/scss/_mixins.scss';

.pwa-notification {
  position: absolute;
  top: 5rem;
  font-size: 2.5rem;
  left: 10rem;
  display: flex;

  &__content {
    display: flex;
    gap: 2rem;

    &__button {
      font-size: 3rem;
      margin: 10px 0;
    }
  }

  @include screen-min($screen-lg) {
    display: none;
  }
}
</style>

<template>
  <div v-if="show" class="mnotification">
    <div class="left-icon">
      <slot name="lefticon" />
    </div>
    <p class="mnotification-text">
      <slot />
    </p>
    <div class="right-icon">
      <slot name="righticon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: true },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixins.scss";

.mnotification {
  padding: 1em 2em 1em 3.5em;
  height: 5em;
  width: 90%;
  font-size: 0.9em;
  position: relative;

  border-radius: 0em 6.25em 6.25em 0em;
  background: url("../../../assets/images/Notifications/notification-effect.svg"),
    linear-gradient(90deg, #221b38 30.82%, rgba(0, 0, 0, 0) 104.22%);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen-min($screen-xl) {
    height: 7rem;
    padding-left: 5.5rem;
    font-size: 1rem;
  }
}

.mnotification-text {
  color: white;
  font-size: 0.9em;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include screen-min($screen-xl) {
    font-size: 1.1em;
  }
}

.left-icon {
  position: absolute;
  bottom: 0;
  left: -3em;
  // width: 100%;
  width: 6em;
  height: 6em;

  @include screen-min($screen-xl) {
    width: 7.9em;
    height: 7.9em;
  }
}

.right-icon {
  width: 2.5em;
  height: 2.5em;
}
</style>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Splash from '../views/Home/Splash';
import $store from "../store";

import {
    authStateMiddleware,
    registerPageNavigationMiddleware
} from "./middlewares";

// Handle the error when the user tries to access a page that he's accessing already
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Splash',
        component: Splash,
        meta: {
            middleware: ['public'],
            page_name: "splash"
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/Login'),
        meta: {
            middleware: ['public'],
            page_name: "login"
        },
    },
    {
        path: '/impersonate/:admin/:username/:password',
        name: 'Impersonate',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/Impersonate'),
        meta: {
            middleware: ['public'],
            page_name: "impersonate"
        },
    },
    {
        path: '/impersonate-v2/:token/:username/',
        name: 'ImpersonateToken',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/ImpersonateToken'),
        meta: {
            middleware: ['public'],
            page_name: "impersonate_token"
        },
    },
    {
        path: '/password/reset/',
        name: 'RedefinePasswordFirstLogin',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/RedefinePassword'),
        meta: {
            middleware: ['public'],
            page_name: ""
        },
    },
    {
        path: '/password/reset/:token',
        name: 'RedefinePassword',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/RedefinePassword'),
        meta: {
            middleware: ['public'],
            page_name: "reset_password"
        },
    },
    {
        path: '/recover/password',
        name: 'RecoverPassword',
        component: () => import(/* webpackChunkName: "public" */ '../views/Home/RecoverPassword'),
        meta: {
            middleware: ['public'],
            page_name: "recover_password"
        },
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home/Onboarding'),
        meta: {
            middleware: ['protected'],
            page_name: "onboarding"
        },
    },
    {
        path: '/terms-conditions',
        name: 'TermsAndConditions',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home/TermsConditions'),
        meta: {
            middleware: ['protected'],
            page_name: "terms_conditions"
        },
    },
    {
        path: '/avatar-selection',
        name: 'AvatarSelection',
        beforeEnter: (to, from, next) => {
            if ($store.getters['user/hasDefaultAvatar']) {
                next({ name: "Dashboard" })
                return;
            }

            next();
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home/AvatarSelection'),
        meta: {
            middleware: ['protected'],
            page_name: "avatar_selection"
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/index'),
        meta: {
            middleware: ['protected'],
            page_name: "dashboard"
        },
    },
    {
        path: '/missions',
        name: 'Missions',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Missions/index'),
        meta: {
            middleware: ['protected'],
            page_name: "missions"
        },
    },
    {
        path: '/kpis',
        name: 'KPIs',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/KPIs/index'),
        meta: {
            middleware: ['protected'],
            page_name: "kpis"
        },
    },

    {
        path: '/content',
        name: 'Content',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Content/index'),
        meta: {
            middleware: ['protected'],
            page_name: "contents"
        },
    },

    {
        path: '/content/details/:content_id',
        name: 'Content Details',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Content/details'),
        meta: {
            middleware: ['protected'],
        },
        props: true,
    },

    {
        path: '/badges',
        name: 'Badges',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Badges/index'),
        meta: {
            middleware: ['protected'],
            page_name: "badges"
        },
    },
    {
        path: '/messages',
        name: 'Messages',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Messages/index'),
        meta: {
            middleware: ['protected'],
            page_name: "messages"
        },
    },

    {
        path: '/kudos',
        name: 'Kudos',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Kudos/index'),
        meta: {
            middleware: ['protected'],
            page_name: "kudos"
        },
    },

    {
        path: '/store',
        name: 'Store',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Store/index'),
        meta: {
            middleware: ['protected'],
            page_name: "store"
        },
    },

    {
        path: '/rankings',
        name: 'Rankings',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Rankings/index'),
        meta: {
            middleware: ['protected'],
            page_name: "rankings"
        },
    },

    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile/index'),
        meta: {
            middleware: ['protected'],
            page_name: "profile"
        },
    },
    {
        path: '/quiz/:quizz_id',
        name: 'Quiz',
        component: () => import('../views/Quiz/index'),
        meta: {
            middleware: ['protected'],
        },
        props: true,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/FAQ/index'),
        meta: {
            middleware: ['protected'],
            page_name: "faqs"
        },
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/Error'),
        meta: {
            middleware: ['public'],
            page_name: "404"
        },
    },


    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(authStateMiddleware);
router.beforeEach(registerPageNavigationMiddleware);

export default router

// import UsersService from "@/modules/Account/Services/users.service";


// const initialState = {
//     id: "",
//     first_name: "",
//     last_name: "",
//     email: "",
//     profile_picture: "",
//     filters: initialFiltersState,
// };

/*
|--------------------------------------------------------------------------
| User store
|--------------------------------------------------------------------------
*/
export default {
    namespaced: true,
    state: {
        user: null,
        profile: null,
        userGear: {
            head: null,
            torso: null,
            legs: null,
            full_profile_picture: null,
        },
        points: 0,
        cycles: [],
        termsAccepted: false,
    },
    getters: {
        authUser: (state) => {
            return state.user;
        },
        authToken: (state) => {
            return state.user.token;
        },
        userProfile: (state) => {
            return state.profile;
        },
        currentCycleId: (state) => {
            return state.profile.current_cycle_id;
        },
        getCyclesList: (state) => {
            return state.cycles;
        },
        getUserEquippedGear: (state) => {
            return state.userGear;
        },
        isAuthenticated: state => !!state.user,
        hasDefaultAvatar: (state) => {
            if (!state.user?.attributes) return false;

            const defaultAvatar = JSON.parse(state.user.attributes).default_avatar
            return typeof defaultAvatar === "string";
        },
        getDefaultAvatar: (state) => {
            return JSON.parse(state.user.attributes).default_avatar;
        },
        getTermsAccepted: (state) => {
            return state.termsAccepted;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAuthToken(state, token) {
            state.user.token = token;
        },
        setUserPoints(state, points) {
            state.points = points;
            if (state.profile != null && state.profile.points_sum !== undefined) {
                state.profile.points_sum = points;
            }
        },
        setUserProfile(state, profile) {
            state.profile = profile;
        },
        setCyclesList(state, cycles) {
            state.cycles = cycles;
        },
        setUserAttributes(state, attributes) {
            state.user.attributes = attributes;
        },
        setUserEquippedGear(state, userGear) {
            state.userGear = userGear;
        },
        setUserFirstLogin(state, newLogin) {
            state.profile.first_login_of_the_day = newLogin;
        },
        setAcceptedTerms(state, accepted) {
            state.termsAccepted = accepted;
        },
    },
    actions: {
        loginUser: (context, user) => {
            context.commit('setUser', user);
        },
        logoutUser: (context) => {
            context.commit('setUser', null);
            context.commit('setUserProfile', null);
            context.commit('setUserPoints', null);
            context.commit('setCyclesList', null);
            context.commit('setAcceptedTerms', false);
            context.commit('setUserEquippedGear', {head: null, torso: null, legs: null}); // TODO make default state object
        },
        updateUserToken: (context, token) => {
            context.commit('setAuthToken', token);
        },
        updateUserAttributes: (context, attributes) => {
            context.commit('setUserAttributes', attributes);
        },
        updateUserPoints: (context, points) => {
            context.commit('setUserPoints', points);
        },
        updateUserFirstLogin: (context, newLogin) => {
            context.commit('setUserFirstLogin', newLogin);
        },
        updateUserProfile: (context, profile) => {
            context.commit('setUserProfile', profile);
        },
        updateCyclesList: (context, cycles) => {
            context.commit('setCyclesList', cycles);
        },
        updateUserEquippedGear: (context, userGear) => {
            context.commit('setUserEquippedGear', userGear);
        },
        updateAcceptTerms: (context, accepted) => {
            context.commit("setAcceptedTerms", accepted);
        }
    },
};

// this.$store.commit('updatePoints', 10);
<template>
  <div class="splash-screen"></div>
</template>

<script>
export default {
  name: "SplashScreen",
  props: [

  ],
  data() {
    return {};
  },
  watch: {
    "$root.isAuthenticated": {
      deep: true,
      handler() {
        if (this.$root.isAuthenticated) {
          this.$root.handleInternalNavigationTo("Dashboard");
        }
      },
    },
  },
  computed: {},
  created() {


    // this.$store.dispatch('user/loginUser', {

    // });

    if (this.$store.getters['user/isAuthenticated']) {
      this.$root.handleInternalNavigationTo("Dashboard");
    }
  },
  mounted() {
    if (!this.$store.getters['user/isAuthenticated'] &&
      this.$route.meta.middleware.indexOf('protected') > -1) {
      setTimeout(() => {
        this.$root.handleInternalNavigationTo("Login");
      }, 3000);
    } else if (!this.$store.getters['user/isAuthenticated']) {
      setTimeout(() => {
        this.$root.handleInternalNavigationTo("Login");
      }, 3000);
    }
  },
  methods: {},
};
</script>

<style scoped>
.splash-screen {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/splash.svg");
  /* background-image: url("../../assets/images/xmas_splash.svg"); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

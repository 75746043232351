import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";
import $router from "../router";
import $store from "../store";
// import * as authService from "@/modules/Core/Services/Auth/auth.service";

let redirectUrl = null;

/**
 * Check auth state
 * @WARN Must be always first in middleware chain
 */
export const authStateMiddleware = async function (to, from, next) {
  if (!$store.getters['user/isAuthenticated'] && to.meta.middleware.indexOf('protected') > -1) {
    return next({ name: 'Login' })
  }

  // $store.getters['user/getDefaultAvatar']
  const isValid = await checkAuthToken(to);
  if (!isValid) return next({ name: "Login" });

  const hasAccepted = await checkAcceptedTerms(to);
  if (!hasAccepted) return next({name: "TermsAndConditions" });

  const hasDefaultAvatar = checkDefaultAvatar(to);
  if (!hasDefaultAvatar) return next({name: "AvatarSelection" });

  next();
};

/**
 * Check if user authentication token that is stored is still valid
 */
async function checkAuthToken(to) {
  if (['login', 'impersonate', 'impersonate_token', 'splash'].includes(to.meta.page_name) || !$store.getters['user/authUser'] || !$store.getters['user/authUser'].id) return true;

  return await AuthService.checkAuthenticationToken($store.getters['user/authUser'].id)
    .then((response) => {
      if (!response.data.token_is_valid) {
        $router.app.$noty.error("Session expired");
        return false;
      }

      return true;
    })
    .catch((error) => {
      $router.app.$noty.error(error.message);
      return false;
    });


}

async function checkAcceptedTerms(to) {
  // Ignore check if in the login flow, not authenticated and the route is protected
  const loginFlow = ["Splash", "Login", "RedefinePasswordFirstLogin", "Onboarding", "TermsAndConditions"]
  if ($store.getters['user/getTermsAccepted']
    || !$store.getters['user/isAuthenticated']
    || loginFlow.includes(to.name)
    || to.meta.middleware.indexOf('public') > -1)
    return true;

  return await UserService.checkTermsAndConditions()
    .then(response => {
      if (response.data.accepted_terms === 1) {
        $store.dispatch("user/updateAcceptTerms", true);
        return true;
      }

      $store.dispatch("user/updateAcceptTerms", false);
      return false;
    })
    .catch(() => {
      $store.dispatch("user/updateAcceptTerms", false);
      return false;
    });
}

function checkDefaultAvatar(to) {
  // Ignore check if in the login flow, not authenticated and the route is protected
  const loginFlow = ["Splash", "Login", "RedefinePasswordFirstLogin", "Onboarding", "TermsAndConditions", "AvatarSelection"]
  if (!$store.getters['user/isAuthenticated']
    || to.meta.middleware.indexOf('public') > -1
    || loginFlow.includes(to.name)) return true;

  return $store.getters['user/hasDefaultAvatar'];
}

function _setAuthToken(token) {
  // localStorage.setItem("accessToken", response.data.token);
  $store.commit("updateUserToken", token);
}



export const registerPageNavigationMiddleware = function (to, from, next) {
  if (to.meta.page_name) {
    const prevPage = localStorage.getItem("previousPageName") ?? "no_page";

    if (prevPage != to.meta.page_name) {
      UserService.registerPageNavigation(to.meta.page_name);
      localStorage.setItem("previousPageName", to.meta.page_name);
    }
  }

  next();
};


// export function getAccessToken() {
//   return localStorage.getItem("accessToken");
// }
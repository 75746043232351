import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNoty from 'vuejs-noty'

Vue.config.productionTip = false

// DIRECTIVES
//// Click Outside
import ClickOutsideDirective from "@/directives/ClickOutsideDirective";
Vue.directive("outside-click", ClickOutsideDirective);

import './registerServiceWorker'

// Noty
Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: false,
  layout: 'topCenter',
  theme: "bootstrap-v4",
  callbacks: {
    onTemplate: function() {
      let title = '';
      let icon = '';
      if (this.options.type == 'success') {
        title = 'Success';
        icon = `
          <svg class="noty-icon" width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3205 4.6188L4 12.3094V27.6906L17.3205 35.3812L30.641 27.6906V12.3094L17.3205 4.6188ZM34.641 10L17.3205 0L0 10V30L17.3205 40L34.641 30V10Z" fill="white"/>
            <path d="M9.49339 20.5668C9.378 20.4468 9.32031 20.2668 9.32031 20.1468C9.32031 20.0268 9.378 19.8468 9.49339 19.7268L10.3011 18.8868C10.5319 18.6468 10.878 18.6468 11.1088 18.8868L11.1665 18.9468L14.3395 22.4868C14.4549 22.6068 14.628 22.6068 14.7434 22.4868L22.4742 14.1468H22.5319V14.1468C22.7626 13.9068 23.1088 13.9068 23.3395 14.1468L24.1472 14.9868C24.378 15.2268 24.378 15.5868 24.1472 15.8268L14.9165 25.7868C14.8011 25.9068 14.6857 25.9668 14.5126 25.9668C14.3395 25.9668 14.2242 25.9068 14.1088 25.7868L9.60877 20.7468L9.49339 20.5668V20.5668Z" fill="white"/>
          </svg>`;
      } else if (this.options.type == 'warning') {
        title = 'Warning';
        icon = `
          <svg class="noty-icon" width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 14H21.6528L17 18.7914L12.3473 14H10.0001L15.8264 20L10 26H12.3473L17 21.2086L21.6528 26H24L18.1736 20L24 14Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3205 4.6188L4 12.3094V27.6906L17.3205 35.3812L30.641 27.6906V12.3094L17.3205 4.6188ZM34.641 10L17.3205 0L0 10V30L17.3205 40L34.641 30V10Z" fill="white"/>
          </svg>`;
      } else if (this.options.type == 'error') {
        title = 'Error';
        icon = `
          <svg class="noty-icon" width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 14H21.6528L17 18.7914L12.3473 14H10.0001L15.8264 20L10 26H12.3473L17 21.2086L21.6528 26H24L18.1736 20L24 14Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3205 4.6188L4 12.3094V27.6906L17.3205 35.3812L30.641 27.6906V12.3094L17.3205 4.6188ZM34.641 10L17.3205 0L0 10V30L17.3205 40L34.641 30V10Z" fill="white"/>
          </svg>`;
      } else {
        title = 'Notification';
      }

      this.barDom.innerHTML = `
        <div class="my-custom-template noty_body">
          ${icon}
          <h2 class="noty-title">${title}</h2>
          <span class="noty-msg">${this.options.text}</span>
          <svg class="close-icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M40.8198 10H35.1798L23.9998 21.18L12.8198 10H7.17995L21.1798 24L7.17969 38H12.8198L23.9998 26.82L35.1798 38H40.8198L26.8198 24L40.8198 10Z" fill="white"/>
          </svg>
        <div>
      `;
    }
  }
})

new Vue({
  data: {
    isCoreLoaded: false,
    // should move to a mixin of authentication;
    isAuthenticated: false,
    account: null, //
    user: null, // @TODO(Nuno) remove from here, put into storage
  },
  created() {

    //todo: remove hardcoded testing...
    this.account = {
      name: 'Joao Morais',
      token: 'xpto',

    };


  },
  mounted() {

  },
  methods: {
    handleInternalNavigationTo( routeName ) {
      this.$router.push({
        name: routeName,
      })
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')

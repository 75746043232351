/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
      //window.location.reload(true);
    },
    updated () {
      console.log('New content is available; please refresh.');
      // You can show a UI element to notify the user about the update and offer them a way to trigger the update.
      const updateNotification = document.createElement("div");
      updateNotification.textContent =
        "Há uma atualização na aplicação. Clica aqui para atualizar!";
      updateNotification.style.position = "fixed";
      updateNotification.style.bottom = "0";
      updateNotification.style.left = "0";
      updateNotification.style.right = "0";
      updateNotification.style.backgroundColor = "lightgray";
      updateNotification.style.padding = "10px";
      updateNotification.style.textAlign = "center";
      updateNotification.style.cursor = "pointer";

      updateNotification.addEventListener("click", () => {
        // Perform the update logic here
        // For example, you could unregister the service worker and refresh the page.
        // Unregistering the service worker will cause the updated one to take effect on the next visit.
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            registration.unregister().then(() => {
              window.location.reload();
            });
          }
        });
      });

      document.body.appendChild(updateNotification);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

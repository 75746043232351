// HTTP client abstraction
const axios = require("axios").default;

function _throw(message) {
    return {
        data: null,
        success: false,
        message
    }
}

axios.interceptors.response.use(
    function ({ data }) {
        if (!data.status) {
            return Promise.reject(_throw(data.message));
        }
        return {
            data: data.content,
            success: data.status,
            message: data.message
        };
    },
    function (error) {
        return Promise.reject(_throw(error.message));
    }
);

axios.interceptors.request.use(function (config) {
    // if (config.requiresAuth) {
    //   config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
    // }

    // @TODO(Nuno) throw if no token + separate token in localstorage + check if true token exists.


    // @TODO(Nuno) Properly pass auth requirement through constructor
    let requireAuth = config.data?.hasOwnProperty('auth') ? config.data.auth : true;
    // let requireAuth = config.data.auth === undefined ? true : config.data.auth;

    if (config.url.includes('impersonate')) {
        requireAuth = false;
    }

    if (requireAuth) {
        config.data = {
            ...config.data,
            token: JSON.parse(localStorage.getItem('vuex'))?.user.user.token ?? null
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


export default {
    get: (...args) => axios.get(...args),
    post: (...args) => axios.post(...args),
    put: (...args) => axios.put(...args),
    delete: (...args) => axios.delete(...args),
};
const httpClient = require("@/services/helpers/http.client").default;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;

export default class UserService {
    static getUserProfile() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/profile`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    static saveUserProfile(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/update`, { ...params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get list of cycles, up to current cycle.
     * @returns 
     */
    static getCyclesList(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/cycles/list`, { ...params })
                .then((response) => {
                    let cycles = response.data;
                    resolve(cycles);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get list of jobs.
     * @returns 
     */
    static getJobsList(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/jobs/list`, { ...params })
                .then((response) => {
                    let jobs = response.data;
                    resolve(jobs);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
       * Get list of groups. // TODO Move to groups service?
       * @returns 
       */
    static getFQAs() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/rh/faqs`)
                .then((response) => {
                    let groups = response.data;
                    resolve(groups);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get list of groups. // TODO Move to groups service?
     * @returns 
     */
    static getGroupsList(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/groups/listWithParents`, { ...params })
                .then((response) => {
                    let groups = response.data;
                    resolve(groups);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
       * Get list of group types.
       * @returns 
       */
    static getGroupTypesList(params) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/groupTypes/list`, { ...params })
                .then((response) => {
                    let groupTypes = response.data;
                    resolve(groupTypes);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Register navigation of user to a given page
     * @returns 
     */
    static registerPageNavigation(page_name) {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/register-page-navigation`, { page_name })
                .then((response) => {
                    let page = response.data;
                    resolve(page);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Get user daily resume
     * @returns 
     */
    static getDailyResume() {
        return new Promise((resolve, reject) => {
            return httpClient.post(`${baseApiUrl}/users/daily/resume`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    }

    /**
     * Accept Terms and Conditions
     * @returns
     */
    static acceptTermsAndConditions() {
        return httpClient.post(`${baseApiUrl}/users/accept-terms`);
    }

    /**
     * Deny Terms and Conditions
     * @returns
     */
    static rejectTermsAndConditions() {
        return httpClient.post(`${baseApiUrl}/users/reject-terms`);
    }

    /**
     * Check if Terms and Conditions was accepted
     * @returns
     */
    static checkTermsAndConditions() {
        return httpClient.post(`${baseApiUrl}/users/check-terms`);
    }
}
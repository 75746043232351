<template>
    <nav>
        <div class="logo">
            <logo />
            <!-- <XmasLogo /> -->
        </div>
        <div class="links">
            <router-link
                v-for="m in menu"
                :key="m.label"
                :to="{ name: m.router }"
                >{{ m.label }}</router-link
            >
        </div>
        <div class="user-data">
            <!-- <div>
                <img
                    class="decorator"
                    src="@/assets/images/misc/xmas_decorator.svg"
                    alt="xmas decor"
                />
            </div> -->
            <div title="Pontos Temporários">
                <img
                    class="icon"
                    src="@/assets/images/misc/temp_points_icon.svg"
                    alt="temp points"
                />
                <span v-if="userProfile">{{
                    userProfile.points_temp_sum
                }}</span>
            </div>
            <div
                :title="`Pontos Definitivos (${userProfile.toExpire.remaining_points} pts expiram no dia ${formatDate(userProfile.toExpire.expiration_date)})`"
            >
                <img
                    class="icon"
                    src="@/assets/images/misc/currency_icon_no_circle.svg"
                    alt="currency"
                />
                <span v-if="userProfile">{{ userProfile.points_sum }}</span>
            </div>
            <div
                class="profile"
                @click="handleDropdown"
                v-outside-click="handleOutsideDropdown"
            >
                <img
                    class="profile-icon"
                    src="@/assets/images/misc/hero_placeholder.svg"
                    alt=""
                />
                <span>{{ navUsername }}</span>
                <span class="dropdown-icon">
                    <img src="@/assets/images/icons/arrow_down.svg" alt="" />
                </span>
                <div v-if="dropdownIsOpen" class="profile-dropdown">
                    <ul>
                        <li @click="logout">Logout</li>
                    </ul>
                </div>
            </div>
        </div>
        <button class="hamburguer-menu" @click="toggleMobileMenu">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
            </svg>
        </button>
        <div v-if="isMobileMenuOpen" class="mobile-navbar">
            <router-link
                v-for="m in menu"
                :key="m.label"
                :to="{ name: m.router }"
                @click.native="toggleMobileMenu"
            >
                {{ m.label }}
            </router-link>
        </div>
    </nav>
</template>
<script>
import Logo from "@/components/Header/Atomic/Logo";
/* import XmasLogo from "@/components/Header/Atomic/XmasLogo"; */
import StringManipulator from "@/tools/StringManipulator";
import { mapGetters } from "vuex";

export default {
    name: "NavbarComponent",
    components: { Logo },
    /* components: { XmasLogo }, */
    data() {
        return {
            menu: [
                { label: "DASHBOARD", router: "Dashboard" },
                { label: "MISSÕES", router: "Missions" },
                { label: "INDICADORES", router: "KPIs" },
                { label: "RANKING", router: "Rankings" },
                { label: "CONTEÚDOS", router: "Content" },
                { label: "BADGES", router: "Badges" },
                { label: "MENSAGENS", router: "Messages" },
                { label: "KUDOS", router: "Kudos" },
                { label: "LOJA", router: "Store" },
                { label: "FAQS", router: "FAQ" },
            ],
            dropdownIsOpen: false,
            isMobileMenuOpen: false,
        };
    },
    computed: {
        ...mapGetters("user", ["authUser", "userProfile"]),
        navUsername() {
            return StringManipulator.getFirstAndLastName(this.authUser.name);
        },
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        },
        logout() {
            // @TODO(Nuno) finish this once auth service and state is done
            // authService.deleteAuthData();
            // this.$store.dispatch("unsetAuthUser").then(() => {
            //   this.$noty.success("You are logged out");
            //   this.$router.push({ name: "login" }).catch(() => {});
            // });
            this.$store.dispatch("user/logoutUser");
            this.$router.push({ name: "Login" }).catch(() => {});
        },
        handleDropdown() {
            this.dropdownIsOpen = !this.dropdownIsOpen;
            this.isMobileMenuOpen = false;
        },
        handleOutsideDropdown() {
            this.dropdownIsOpen ? (this.dropdownIsOpen = false) : null;
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
    },
    watch: {
        isMobileMenuOpen(newValue) {
            if (newValue) {
                document.body.classList.add("noscroll");
                document.documentElement.classList.add("noscroll");
                return;
            }

            document.body.classList.remove("noscroll");
            document.documentElement.classList.remove("noscroll");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";
@import "@/assets/scss/_variables.scss";

$navbar-height: 3.5625rem;
nav {
    position: fixed;
    top: 0;
    left: 0;
    background: #161128;
    padding: 0 13px;
    display: flex;
    align-items: center;
    height: $navbar-height;
    z-index: 9000;
    width: 100%;
    font-size: 1.5rem;

    @include screen-min($screen-lg) {
        font-size: 1rem;
    }

    & .logo {
        display: flex;
        align-items: center;
        flex-grow: 1;
        & svg {
            width: 10rem;
            height: 2.5rem;
        }
        // margin-right: 2rem;
        // @include screen-min($screen-xl) {
        //   margin-right: 11.3125rem;
        // }
    }

    & > .links {
        display: none;
        height: 100%;
        background: #161128;
        height: 100%;

        @include screen-min($screen-lg) {
            display: flex;
        }

        & a {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            font-weight: 500;
            font-size: 1.125em;
            height: 100%;
            padding: 0 0.9375em;

            &:hover {
                background: #241c41;
            }

            &.router-link-active {
                font-weight: 700;
                background: #322856;
            }
        }
    }

    & > .user-data {
        flex-grow: 1;
        text-align: right;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.8125em;

        & div {
            display: flex;
            color: white;
            font-size: 1.125em;
            font-weight: 500;
            gap: 0.35em;
            align-items: center;

            & .icon {
                width: 1em;
                height: 2rem;
            }

            & .decorator {
                width: 7em;
                height: 4rem;
            }

            &.profile {
                cursor: pointer;
                position: relative;

                & .profile-icon {
                    width: 1.4em;

                    @include screen-min($screen-lg) {
                        width: 1.875em;
                    }
                }

                & .dropdown-icon {
                    width: 1.25em;
                    height: 1.25em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .profile-dropdown {
                    position: absolute;
                    background: white;
                    width: 12em;
                    top: 2.75em;
                    right: 0.5em;
                    z-index: 100;

                    & ul {
                        width: 100%;
                        text-align: left;

                        & li {
                            color: black;
                            padding: 0.375em 1em;

                            &:hover {
                                background: #eee;
                            }
                        }
                    }
                }
            }
        }
    }

    & .hamburguer-menu {
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;
        height: 100%;
        color: white;
        margin-left: 0.625rem;
        padding: 0 0.9375rem;

        @include screen-min($screen-lg) {
            display: none;
        }

        & > svg {
            width: 2em;
        }

        &:hover {
            background: #241c41;
        }
    }

    .mobile-navbar {
        background: #161128;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(0rem + $navbar-height);
        left: 0;
        width: 100%;
        z-index: 99999;
        overflow: auto;
        text-align: center;
        height: calc(100vh - $navbar-height);
        padding-bottom: calc(2 * $navbar-height);

        @include screen-min($screen-lg) {
            display: none;
        }

        & > a {
            color: white;
            font-weight: 500;
            font-size: 2rem;
            padding: 0.625rem;

            &:hover {
                background: #241c41;
            }

            &.router-link-active {
                font-weight: 700;
                background: #322856;
            }
        }
    }
}
</style>

<template>
  <div id="app">
    <template v-if="$store.getters['user/isAuthenticated'] && !isPublic && !hasNoNavbar">

      <NavbarComponent/>

    </template>

    <div class="view-container">
      <router-view/>
    </div>

    <pwa-notification v-if="showInstallNotification" :deferredPrompt="deferredPrompt" />
  </div>
</template>

<style lang="scss">
@import './assets/scss/app.scss';
#app {
  width: 100%;
  min-height: 100vh;
  background-image: url('./assets/images/background.svg');
  /* background-image: url('./assets/images/xmas_bg.svg'); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
  position: relative;
}
.view-container {
  padding-top: $m-navbar-height;
}
</style>
<script>
import NavbarComponent from "@/components/Header/Navbar";
import PwaNotification from "./components/PwaNotification.vue";

export default {
  components: { NavbarComponent, PwaNotification },
  data() {
    return {
      deferredPrompt: null,
      showInstallNotification: false,
    }
  },
  computed: {
    isPublic() {
      return this.$route.meta.middleware?.includes("public");
    },
    hasNoNavbar() {
      return ['Onboarding', 'TermsAndConditions'].includes(this.$route.name);
    }
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallNotification = true;
    });
  },
};
</script>


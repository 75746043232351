export default class StringManipulator {
    /**
     * 
     * @param {*} name 
     * @returns the first and last letters of the first and last name of a name with any given number of names
     * @example Carla Ferreira Santos Vasconcelos => CV
     */
    static getNameFirstAndLastLetters(name) {
        const names = name.split(" ");

        if (names.length == 1) {
            return names[0][0];
        }

        return names[0][0] + names[names.length - 1][0];
    }

    /**
     * 
     * @param {*} name 
     * @returns the first and last name of a name with any given number of names
     * @example Carla Ferreira Santos Vasconcelos => Carla Vasconcelos
     */
    static getFirstAndLastName(name) {
        const names = name.split(" ");

        if (names.length == 1) {
            return names[0];
        }

        return names[0] + " " + names[names.length - 1];
    }
}